import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { Link } from "gatsby"

export default function Template({
                                   data, // this prop will be injected by the GraphQL query below.
                                    pageContext
                                 }) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  const { prev, next } = pageContext
  return (
    <Layout title={frontmatter.title}>
      <nav id="nav-single">
        <h3 className="assistive-text">Post navigation</h3>
        {prev && <span className="nav-previous"><Link to={prev.frontmatter.path}>
            <span className="meta-nav">←</span> Previous
          </Link></span>
        }
        {next && <span className="nav-next"><Link to={next.frontmatter.path}>
          Next <span className="meta-nav">→</span>
        </Link></span>
        }
      </nav>

      <article id="post-31" className="post-31 post type-post status-publish format-standard hentry category-uncategorized">
        <header className="entry-header">
          <h1 className="entry-title">{frontmatter.title}</h1>
          <div className="entry-meta">
            <span className="sep">Posted on </span><a href={frontmatter.path} rel="bookmark">
            <time className="entry-date" dateTime="" pubdate="">{frontmatter.date}</time>
          </a><span className="by-author"> <span className="sep"></span>
          </span></div>
        </header>
        <div
          className="entry-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
